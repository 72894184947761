<template>
  <label class="sam-label mb-1 mt-0" v-if="label">{{ label }}</label>
</template>

<script>
export default {
  name: 'SamLabel',
  props: {
    label: {
      type: String,
      default: ''
    },
  }
}
</script>
<style lang="scss" scoped>
  .sam-label {
    font-family: $boldFontStyle;
  }
</style>