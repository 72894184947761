<template>
  <div class="sam-form-control mb-4">
    <SamLabel :label=label noSpace/>
    <input
        class="form-control sam-input sam-text-field"
        :class="{'error': error}"
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        :pattern="pattern"
    >
    <SamErrorMessage :error="error" :errorMessage="errorMessage"/>
  </div>
</template>

<script>

import SamLabel from './SamLabel.vue'
import SamErrorMessage from './SamErrorMessage.vue'

export default {
  name: 'SamTextField',
  components: {SamLabel, SamErrorMessage},
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    pattern: {},
    value: {},
  
  }
}
</script>
<style lang="scss" scoped>

.sam-form-control {
  position: relative;
}

.sam-input.sam-text-field {
  height: 3rem;
  box-shadow: none;
  border-radius: $mainRadius;
  border: 1px solid $lightGrey;
  color: $mainColor;
}

.sam-text-field.error {
  border-color: $alertRed;
}

.sam-text-field:focus, 
.sam-text-field:active {
  box-shadow: none;
  border: 1px solid #EAEAEA;
}

.sam-text-field:disabled {
  background-color: $white;
  opacity: 0.5;
}

</style>